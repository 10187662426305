import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const Contact = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="Contact | Lantern of the Soul" desc="Lantern of the Soul Photography" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Contact us</h1>
        <sup>We're currently in Southern California, give us a call or shoot us an email to schedule a photography session!</sup>
        <p>
          {' '}<a href="tel:+17606892203">(760) 689-2203</a><br />
	  {' '}<a href="mailto:inquiry@lanternofthesoul.com">inquiry@lanternofthesoul.com</a>.
	  {' '}<a href="https://www.instagram.com/lanternofthesoul/" target="_blank">Instagram</a>
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default Contact
